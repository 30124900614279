
import { getAuth, signOut, User } from 'firebase/auth';
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import ProBadge from '@/components/ProBadge.vue';
import KycNotEligibleBanner from '@/components/KycNotEligibleBanner.vue'
import CONSTS from '@/utils/constants';

export default defineComponent({
  components: { ProBadge, KycNotEligibleBanner },
  setup() {
    const auth = getAuth();
    const store = useStore();
    const userStates = CONSTS.USER_STATES;

    const user = computed<User>(() => store.getters['user/user']);

    const displayName = computed(() => {
      return user.value?.email || user.value?.displayName;
    });

    const isNotEligible = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_NOT_ELIGIBLE
    );

    const registrationDate = computed<string>(() => {
      const creationTime = user.value?.metadata?.creationTime;
      if (creationTime) {
        const date = new Date(creationTime);
        const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
          date
        );
        const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(
          date
        );
        const d = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
          date
        );
        return `${m} ${d}, ${y}`;
      } else {
        return '';
      }
    });

		const isPro = computed<boolean>(() => store.getters['user/isPro']);

    const userSignOut = () => {
      signOut(auth);
    };

    return { userSignOut, displayName, registrationDate, isPro, CONSTS, isNotEligible };
  },
});
